import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchGatePassById } from '../../utils/api';
import { generatePDF } from '../../utils/pdfUtils';
import { format } from 'date-fns';
import { getCsrfToken } from '../../utils/csrf';  // CSRF token utility
import apiBaseUrl from '../../config';  // Import the base URL from config
import './GatePassDetail.css';

const GatePassDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [gatePass, setGatePass] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);  // Loading state for the button

  useEffect(() => {
    fetchGatePassById(id)
      .then((data) => setGatePass(data))
      .catch((error) => {
        console.error('Error fetching gate pass:', error.message);
        setError('Failed to load gate pass details. Please try again later.');
      });
  }, [id]);

  // Polling: Periodically fetch gate pass data to check for status change
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchGatePassById(id)
        .then((newData) => {
          if (newData.status !== gatePass?.status) {
            setGatePass(newData);
          }
        })
        .catch((error) => {
          console.error('Error fetching gate pass status:', error.message);
        });
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [id, gatePass]);

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (!gatePass) {
    return <p className="loading">Loading...</p>;
  }

  const {
    issue_date: issueDate,
    return_date: returnDate,
    from_address: originAddress,
    delivered_by: deliveredBy,
    deliverers_phone: deliverersPhone,
    to_address: destinationAddress,
    receiver,
    receivers_phone: receiversPhone,
    items,
    status
  } = gatePass;

  const isNotReturnable = !returnDate;

  const handleDownloadPDF = () => {
    console.log('Generating PDF for Gate Pass ID:', id);
    generatePDF('gatepass-detail-container', id);
  };

  const handleReturnReceived = async () => {
    setIsLoading(true);  // Set loading state to true
    try {
      const csrfToken = getCsrfToken();  // Retrieve the CSRF token
      const response = await fetch(`${apiBaseUrl}/api/gatepass/return-received/${id}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,  // Include CSRF token in header
        },
        credentials: 'include',  // Ensure session cookies are included
      });

      if (!response.ok) {
        const responseText = await response.text();
        console.error('Server response:', responseText);
        throw new Error('Failed to send return confirmation email');
      }

      const data = await response.json();
      alert(data.message);
      setGatePass((prevGatePass) => ({ ...prevGatePass, status: 'Received' }));

    } catch (error) {
      alert(`Error sending return confirmation email: ${error.message}`);
    } finally {
      setIsLoading(false);  // Reset loading state to false
    }
  };

  const statusIcon = (status) => {
    switch (status) {
      case 'Approved':
        return <img src={`${process.env.PUBLIC_URL}/Images/approved.png`} alt="Approved" className="status-icon" />;
      case 'Rejected':
        return <img src={`${process.env.PUBLIC_URL}/Images/rejected.png`} alt="Rejected" className="status-icon" />;
      case 'Return Confirmed':
        return <img src={`${process.env.PUBLIC_URL}/Images/confirmed.png`} alt="confirmed" className="status-icon" />;
      default:
        return <img src={`${process.env.PUBLIC_URL}/Images/pending.png`} alt="Pending" className="status-icon" />;
    }
  };

  return (
    <div className="gatepass-detail-wrapper">
      {/* Back Button */}
      <div className="back-button-container">
        <button 
          className="back-button" 
          onClick={() => navigate(-1)}
        >
          <i className="fas fa-arrow-left"></i> Back
        </button>
      </div>

      <div className="action-buttons">
        <img 
          src={`${process.env.PUBLIC_URL}/Images/print.jpg`} 
          alt="Print" 
          className="action-button" 
          onClick={() => window.print()} 
        />
        <img 
          src={`${process.env.PUBLIC_URL}/Images/pdf.png`} 
          alt="Download PDF" 
          className="action-button" 
          onClick={handleDownloadPDF}
        />
        {!isNotReturnable && status === 'Approved' && (
          <button 
            className="return-button" 
            onClick={handleReturnReceived}
            disabled={isLoading}  // Disable button while loading
          >
            {isLoading ? 'Processing...' : 'Return Received'}
          </button>
        )}
      </div>

      <div id="gatepass-detail-container" className="gatepass-detail-container">
        <h1 className="heading">Gate Pass</h1>
        <div className="logo-container">
          <img src={`${process.env.PUBLIC_URL}/Images/union.png`} alt="Company Logo" className="company-logo" />
        </div>
        <h3 className="title">{id}</h3>

        <div className="detail-group">
          <p><strong>Issue Date:</strong> {issueDate ? format(new Date(issueDate), 'dd/MM/yyyy') : 'N/A'}</p>
          <p><strong>Return Date:</strong> {isNotReturnable ? 'Not Returnable' : format(new Date(returnDate), 'dd/MM/yyyy')}</p>
        </div>

        <div className="preview-section">
          <div className="gatepass-row">
            <div className="gatepass-column">
              <p><strong>From:</strong> {originAddress}</p>
              <p><strong>Delivered By:</strong> {deliveredBy}</p>
              <p><strong>Phone:</strong> {deliverersPhone}</p>
            </div>
            <div className="gatepass-column">
              <p><strong>To:</strong> {destinationAddress}</p>
              <p><strong>Receiver:</strong> {receiver}</p>
              <p><strong>Phone:</strong> {receiversPhone}</p>
            </div>
          </div>

          <table className="gatepass-table1">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Model No.</th>
                <th>Serial No.</th>
                <th>Asset Tag</th>
                <th>Qty</th>
                <th>Reason</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {items.length === 0 ? (
                <tr>
                  <td colSpan="7">No items to display</td>
                </tr>
              ) : (
                items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.item_name}</td>
                    <td>{item.model_no}</td>
                    <td>{item.serial_no}</td>
                    <td>{item.asset_tag}</td>
                    <td>{item.quantity}</td>
                    <td>{item.reason}</td>
                    <td>{item.remarks}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="status-section">
          {statusIcon(status)}
          <p><strong>Approval Status:</strong> {status || 'Pending'}</p>
        </div>
      </div>
    </div>
  );
};

export default GatePassDetail;
