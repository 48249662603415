// src/utils/pdfUtils.js
import html2pdf from 'html2pdf.js';

export const generatePDF = (elementId, gatePassId) => {
  // Get the HTML element you want to convert to PDF
  const element = document.getElementById(elementId);

  if (!element) {
    console.error('Element not found:', elementId);
    return;
  }

  if (!gatePassId) {
    console.error('Gate Pass ID is undefined');
    return;
  }

  // Apply the same styles used in print preview and adjust for A4 paper size
  const clone = element.cloneNode(true);
  const style = `
    <style>
      body, html {
        width: 100%;
        margin: 0;
        padding: 0;
        font-family: Arial, sans-serif;
      }
      .gatepass-detail-container {
        width: 100%;
        margin: 0 auto;
        padding: 10px;
        box-sizing: border-box;
      }
      h1.heading {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
      }
      .logo-container {
        text-align: center;
        margin-bottom: 20px;
      }
      .gatepass-table {
        width: 100%;
        border-collapse: collapse;
        margin: 0 auto;
      }
      .gatepass-table th, .gatepass-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
        font-size: 12px;
      }
      .gatepass-table th {
        background-color: #f2f2f2;
      }
      .status-section img {
        width: 50px;
        height: auto;
      }
      .company-logo {
        width: 100px;
        height: auto;
      }
      .detail-group, .preview-section, .status-section {
        margin-bottom: 20px;
      }
    </style>
  `;

  // Append the style to the cloned element
  clone.innerHTML = style + clone.innerHTML;

  // Options for the PDF
  const options = {
    margin: [10, 10, 10, 10],  // Small margin for better fit
    filename: `GatePass_${gatePassId}.pdf`,  // Use the Gate Pass ID in the filename
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  };

  // Generate and save the PDF
  html2pdf().from(clone).set(options).save();
};
