import React, { useState } from 'react';
import axios from 'axios';
import './SignUp.css';
import { Link, useNavigate } from 'react-router-dom';
import apiBaseUrl from '../../config'; // Import the base URL from config.js

const SignUp = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    department: '',
    department_location: '',
    role: '',
    phone_number: '',
  });
  const [errors, setErrors] = useState({});
  const [signedUp, setSignedUp] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate(); // Initialize navigate

  const departmentOptions = [
    'Admin',
    'IT',
    'Supply Chain',
    'Transport',
    'Security',
    'DBL',
    'SRD',
    'HR',
    'Audit',
    'Finance & Accounts',
    'Visa Processing',
    'Sales Support',
    'UPL',
    'Tax & Vat',
  ];

  const departmentLocationOptions = [
    '68/1 Gulshan Avenue, Dhaka 1212',
    'Palash - Ghorashal Rd, Ghorashal',
    '106-123, DEPZ-Extension Area, Dhaka, Bangladesh, Vadail Road, Baipayl',
    // Add more as needed
  ];

  const roleOptions = [
    'User',
    'Manager',
    'Security Incharge',
    // Add more roles as needed
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = {};
    let hasErrors = false;

    // Validation checks
    if (!formData.username) {
      formErrors.username = 'Username is required.';
      hasErrors = true;
    }
    if (!formData.email) {
      formErrors.email = 'Email is required.';
      hasErrors = true;
    }
    if (!formData.password) {
      formErrors.password = 'Password is required.';
      hasErrors = true;
    }
    if (!formData.phone_number) {
      formErrors.phone_number = 'Phone number is required.';
      hasErrors = true;
    }
    if (!formData.department) {
      formErrors.department = 'Please select a department.';
      hasErrors = true;
    }
    if (!formData.department_location) {
      formErrors.department_location = 'Department location is required.';
      hasErrors = true;
    }
    if (!formData.role) {
      formErrors.role = 'Role is required.';
      hasErrors = true;
    }
    if (hasErrors) {
      setErrors(formErrors);
      return;
    }

    setLoading(true); // Set loading state

    try {
      const response = await axios.post(`${apiBaseUrl}/api/users/signup/`, formData);
      console.log('Response:', response.data);
      setSignedUp(true);
      setFormData({
        username: '',
        email: '',
        password: '',
        department: '',
        department_location: '',
        role: '',
        phone_number: '',
      });
      setErrors({});
    } catch (error) {
      if (error.response && error.response.data) {
        const backendErrors = {};
        for (let key in error.response.data) {
          backendErrors[key] = error.response.data[key][0];
        }
        setErrors(backendErrors);
      } else {
        console.error('Sign up failed:', error.message);
        setErrors({ common: 'Sign up failed. Please try again.' });
      }
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
  };

  return (
    <div className="signup-container">
      <div className="center">
        {!signedUp ? (
          <>
            <h2>Sign Up</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  placeholder="Enter your username"
                  className={errors.username ? 'error' : ''}
                />
                {errors.username && <p className="error-message">{errors.username}</p>}
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  className={errors.email ? 'error' : ''}
                />
                {errors.email && <p className="error-message">{errors.email}</p>}
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  className={errors.password ? 'error' : ''}
                />
                {errors.password && <p className="error-message">{errors.password}</p>}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  className={errors.phone_number ? 'error' : ''}
                />
                {errors.phone_number && <p className="error-message">{errors.phone_number}</p>}
              </div>
              <div className="form-group">
                <select
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                  className={errors.department ? 'error' : ''}
                >
                  <option value="">Select Department</option>
                  {departmentOptions.map((department, index) => (
                    <option key={index} value={department}>{department}</option>
                  ))}
                </select>
                {errors.department && <p className="error-message">{errors.department}</p>}
              </div>
              <div className="form-group">
                <select
                  name="department_location"
                  value={formData.department_location}
                  onChange={handleChange}
                  className={errors.department_location ? 'error' : ''}
                >
                  <option value="">Select Department Location</option>
                  {departmentLocationOptions.map((location, index) => (
                    <option key={index} value={location}>{location}</option>
                  ))}
                </select>
                {errors.department_location && <p className="error-message">{errors.department_location}</p>}
              </div>
              <div className="form-group">
                <select
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  className={errors.role ? 'error' : ''}
                >
                  <option value="">Select Role</option>
                  {roleOptions.map((role, index) => (
                    <option key={index} value={role}>{role}</option>
                  ))}
                </select>
                {errors.role && <p className="error-message">{errors.role}</p>}
              </div>
              {errors.non_field_errors && <p className="error-message">{errors.non_field_errors}</p>}
              {errors.common && <p className="error-message">{errors.common}</p>}
              <button type="submit" disabled={loading}>
                {loading ? 'Signing Up...' : 'Sign Up'}
              </button>
            </form>
            
            {/* Back Button */}
            <div className="back-button-container">
              <button 
                className="back-button" 
                onClick={() => navigate(-1)}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        ) : (
          <div className="success-message">
            <p><span className="success-icon">&#10003;</span> <strong>Signup successful!</strong></p>
            <div className="back-to-signin">
              <Link to="/">Back to Sign In</Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUp;
