// src/components/Dashboard/Dashboard.js
import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import MainContent from './MainContent';
import './Dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <Sidebar />
      <div className="main">
        <Header />
        <MainContent />
      </div>
    </div>
  );
};

export default Dashboard;
