// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Authentication/Login';
import SignUp from './components/Authentication/SignUp';
import Dashboard from './components/Dashboard/Dashboard';
import GatePassForm from './components/GatePassForm/GatePassForm';
import GatePasses from './components/GatePasses/GatePasses';
import GatePassDetail from './components/GatePassDetail/GatePassDetail';
import './App.css'; // Assuming you have an App.css file for global styles

const NotFound = () => (
  <div>
    <h1>404 - Page Not Found</h1>
  </div>
);

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Route for login page */}
          <Route path="/" element={<Login />} />
          
          {/* Route for signup page */}
          <Route path="/signup" element={<SignUp />} />
          
          {/* Route for dashboard page */}
          <Route path="/dashboard" element={<Dashboard />} />
          
          {/* Route for gate pass form page */}
          <Route path="/gatepassform" element={<GatePassForm />} />

          {/* Route for listing gate passes */}
          <Route path="/gatepasses" element={<GatePasses />} />
          
          {/* Route for viewing a specific gate pass detail */}
          <Route path="/gatepass/:id" element={<GatePassDetail />} />
          
          {/* Route for 404 (Page Not Found) */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
