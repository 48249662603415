// src/components/Authentication/Login.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { getCsrfToken } from '../../utils/csrf';
import apiBaseUrl from '../../config';  // Import the apiBaseUrl

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      setError('Please enter both username and password.');
      return;
    }
    setLoading(true);
    try {
      const csrfToken = getCsrfToken();
      const response = await axios.post(
        `${apiBaseUrl}/api/users/login/`,  // Use apiBaseUrl here
        { username, password },
        { 
          headers: {
            'X-CSRFToken': csrfToken
          },
          withCredentials: true 
        }
      );
      //console.log('Login success:', response.data);
      setError('');
      setLoading(false);
      navigate('/dashboard'); // Redirect to dashboard on successful login
    } catch (error) {
      console.error('Login failed:', error.response?.data?.error || error.message);
      setError(error.response?.data?.error || 'Login failed. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            disabled={loading}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
};

export default Login;
