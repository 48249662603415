import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchGatePasses } from '../../utils/api';
import apiBaseUrl from '../../config';  // Import the base URL from config
import './GatePasses.css';
import { getCsrfToken } from '../../utils/csrf';  // CSRF token utility

const GatePasses = () => {
  const [gatePasses, setGatePasses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGatePasses, setFilteredGatePasses] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'issue_date', direction: 'desc' });
  const [currentUser, setCurrentUser] = useState('');
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    // Fetch user details
    const fetchUserDetails = async () => {
      try {
        const csrfToken = getCsrfToken();  // Retrieve the CSRF token
        const response = await fetch(`${apiBaseUrl}/api/users/current_user/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,  // Include CSRF token in header
          },
          credentials: 'include',  // Ensure session cookies are included
        });
    
        if (!response.ok) {
          const errorText = await response.text();  // Get the error message
          console.error('Error response:', errorText);
          throw new Error(`Failed to fetch user details. Status: ${response.status}`);
        }
    
        const data = await response.json();
        setCurrentUser(data.username); // Use the actual identifier
        setUserRole(data.role); // Set user role
      } catch (error) {
        console.error('Error fetching user details:', error.message);
      }
    };

    // Fetch gate passes
    const fetchGatePassData = async () => {
      try {
        const data = await fetchGatePasses();
        setGatePasses(data);
      } catch (error) {
        console.error('Error fetching gate passes:', error.message);
      }
    };

    fetchUserDetails();
    fetchGatePassData();
  }, []);

  useEffect(() => {
    // Filter and sort gate passes based on user role and search term
    const filterAndSortGatePasses = () => {
      let filtered = [...gatePasses];
      
      // Filter based on user role
      if (userRole === 'User') {
        // Users can only see their own gate passes
        filtered = filtered.filter(gatePass => gatePass.delivered_by === currentUser);
      } 
      // Managers and Security Incharges see all gate passes
      else {
        // No filtering by user role, all gate passes are shown
      }

      // Sort gate passes based on the sortConfig
      const sortedGatePasses = filtered.sort((a, b) => {
        const dateA = new Date(a[sortConfig.key]);
        const dateB = new Date(b[sortConfig.key]);
        return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
      });

      // Further filter by search term
      const finalFiltered = sortedGatePasses.filter((gatePass) => {
        const id = gatePass.id?.toString() || '';
        const deliveredBy = gatePass.delivered_by?.toLowerCase() || '';
        const issueDate = new Date(gatePass.issue_date).toLocaleDateString('en-GB') || '';
        const returnDate = new Date(gatePass.return_date).toLocaleDateString('en-GB') || '';
        const status = gatePass.status?.toLowerCase() || '';

        return (
          id.includes(searchTerm.toLowerCase()) ||
          deliveredBy.includes(searchTerm.toLowerCase()) ||
          issueDate.includes(searchTerm.toLowerCase()) ||
          returnDate.includes(searchTerm.toLowerCase()) ||
          status.includes(searchTerm.toLowerCase())
        );
      });

      setFilteredGatePasses(finalFiltered);
    };

    filterAndSortGatePasses();
  }, [searchTerm, gatePasses, sortConfig, currentUser, userRole]);

  const handleRowClick = (id) => {
    navigate(`/gatepass/${id}`);
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      const direction = prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc';
      return { key, direction };
    });
  };

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString('en-GB') : 'N/A';
  };

  const handleBackButtonClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="gatepasses-container">
      <button className="back-button" onClick={handleBackButtonClick}>Back</button>
      <h2>All Gate Passes</h2>
      <input
        type="text"
        placeholder="Search gate passes..."
        className="search-input"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="table-wrapper">
        <table className="gatepasses-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Delivered By</th>
              <th
                onClick={() => handleSort('issue_date')}
                style={{ cursor: 'pointer' }}
              >
                Issue Date {sortConfig.key === 'issue_date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </th>
              <th>Return Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredGatePasses.map((gatePass) => (
              <tr key={gatePass.id} onClick={() => handleRowClick(gatePass.id)} style={{ cursor: 'pointer' }}>
                <td>{gatePass.id}</td>
                <td>{gatePass.delivered_by || 'Unknown'}</td>
                <td>{formatDate(gatePass.issue_date)}</td>
                <td>{formatDate(gatePass.return_date)}</td>
                <td>{gatePass.status || 'Unknown'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GatePasses;
