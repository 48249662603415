//frontend/src/components/GatePassForm.js
import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiBaseUrl from '../../config';  // Import the base URL from config
import { getCsrfToken } from '../../utils/csrf';
import './GatePassForm.css';

const GatePassForm = () => {
  const [issueDate, setIssueDate] = useState(new Date().toISOString().split('T')[0]);
  const [returnDate, setReturnDate] = useState('');
  const [isNotReturnable, setIsNotReturnable] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [formData, setFormData] = useState({
    from: '',
    deliveredBy: '',
    phoneNoFrom: '',
    to: '',
    receiver: '',
    phoneNoTo: ''
  });
  const [items, setItems] = useState([
    { name: '', model: '', serialNumber: '', assetTag: '', quantity: '', reason: '', remarks: '' }
  ]);

  useEffect(() => {
    fetchUserData(); // Fetch user data when component mounts
  }, []);
 
  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/users/current_user/`, {
        headers: {
          'X-CSRFToken': getCsrfToken(),
        },
        withCredentials: true, // Include cookies in the request
      });
      const userData = response.data;
      // Populate necessary fields from userData
      setFormData({
        ...formData,
        from: userData.department_location, // Example: Populate Origin Address
        deliveredBy: userData.username, // Example: Populate Delivered By
        phoneNoFrom: userData.phone_number // Example: Populate Deliverer's Phone
        // Add other fields as needed
      });
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    const newDate = new Date(value);
    
    if (name === 'issueDate') {
      setIssueDate(value);
      // Check if returnDate is set and compare it to issueDate
      if (returnDate && returnDate !== 'Not Returnable') {
        const returnDateObj = new Date(returnDate);
        if (returnDateObj < newDate) {
          alert('Return date cannot be earlier than the issue date.');
          setReturnDate(''); // Reset returnDate to avoid invalid state
        }
      }
    } else if (name === 'returnDate') {
      const returnDateObj = new Date(value);
      // Validate if the returnDate is earlier than issueDate
      if (issueDate && returnDateObj < new Date(issueDate)) {
        alert('Return date cannot be earlier than the issue date.');
        setReturnDate(''); // Reset returnDate to avoid invalid state
      } else {
        setReturnDate(value);
      }
    }
  };
  

  const handleNotReturnableChange = (event) => {
    setIsNotReturnable(event.target.checked);
    if (event.target.checked) {
      setReturnDate('');
    }
  };

  const addItem = () => {
    setItems([
      ...items,
      { name: '', model: '', serialNumber: '', assetTag: '', quantity: '', reason: '', remarks: '' }
    ]);
  };

  const removeItem = (index) => {
    if (items.length > 1) {
      setItems(items.filter((_, i) => i !== index));
    } else {
      alert("At least one item must remain in the list.");
    }
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newItems = [...items];
    newItems[index][name] = value;
    setItems(newItems);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsPreview(true);
  };

  const handleEdit = () => {
    setIsPreview(false);
  };

  const navigate = useNavigate();
  
  const handleFinalSubmit = async () => {
    setIsLoading(true); // Start loading
    try {
      const csrfToken = getCsrfToken();
      //console.log('CSRF Token:', csrfToken);
      const response = await fetch(`${apiBaseUrl}/api/gatepass/submit/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({
          issue_date: issueDate,
          return_date: isNotReturnable ? null : returnDate,
          from_address: formData.from,
          delivered_by: formData.deliveredBy,
          deliverers_phone: formData.phoneNoFrom,
          to_address: formData.to,
          receiver: formData.receiver,
          receivers_phone: formData.phoneNoTo,
          items: items.map(item => ({
            item_name: item.name,
            model_no: item.model,
            serial_no: item.serialNumber,
            asset_tag: item.assetTag,
            quantity: item.quantity,
            reason: item.reason,
            remarks: item.remarks,
          })),
        }),
        credentials: 'include', // Important for session-based authentication
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorMessage}`);
      }

      const responseData = await response.json();
      console.log('Gate pass submitted successfully:', responseData);
      alert('Gate pass submitted successfully.');
      setIsPreview(false); // After successful submission, reset to edit mode

      // Redirect to the GatePassDetail page after successful submission
      navigate(`/gatepass/${responseData.id}`); // Use the ID of the newly created gate pass
    } catch (error) {
      console.error('Error submitting gate pass:', error.message);
      alert('Error submitting gate pass. Please contact IT support.');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };


  return (
    <div className="gatepass-form-container">
      <h2>Gate Pass</h2>
      <div className="logo-container">
        <img src={`${process.env.PUBLIC_URL}/Images/union.png`} alt="Company Logo" className="company-logo" />
      </div>
      {isPreview ? (
        <div>
          <p><strong>Issue Date:</strong> {issueDate}</p>
          <p><strong>Return Date:</strong> {isNotReturnable ? 'Not Returnable' : returnDate}</p>
          <div className="preview-section">
            <div className="gatepass-row">
              <div className="gatepass-column">
                <p><strong>Origin Address:</strong> {formData.from}</p>
                <p><strong>Delivered By:</strong> {formData.deliveredBy}</p>
                <p><strong>Deliverer's Phone:</strong> {formData.phoneNoFrom}</p>
              </div>
              <div className="gatepass-column">
                <p><strong>Destination Address:</strong> {formData.to}</p>
                <p><strong>Receiver:</strong> {formData.receiver}</p>
                <p><strong>Receiver's Phone:</strong> {formData.phoneNoTo}</p>
              </div>
            </div>
            <table className="gatepass-table">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Model No.</th>
                  <th>Serial No.</th>
                  <th>Asset Tag</th>
                  <th>Quantity</th>
                  <th>Reason</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.model}</td>
                    <td>{item.serialNumber}</td>
                    <td>{item.assetTag}</td>
                    <td>{item.quantity}</td>
                    <td>{item.reason}</td>
                    <td>{item.remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="edit-button" type="button" onClick={handleEdit}>Edit</button>
          <button
            className="submit-button"
            type="submit"
            onClick={handleFinalSubmit}
            disabled={isLoading} // Disable button when loading
          >
            {isLoading ? 'Submitting...' : 'Submit'} {/* Show loading text */}
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="date-row">
            <div className="date-group">
              <label htmlFor="issueDate">Issue Date:</label>
              <input
                type="date"
                id="issueDate"
                name="issueDate"
                value={issueDate}
                onChange={handleDateChange}
                required
              />
            </div>
            <div className="date-group">
              <label htmlFor="returnDate">Return Date:</label>
              <input
                type="date"
                id="returnDate"
                name="returnDate"
                value={returnDate}
                onChange={handleDateChange}
                disabled={isNotReturnable}
              />
              <label>
                <input
                  type="checkbox"
                  checked={isNotReturnable}
                  onChange={handleNotReturnableChange}
                />
                Not Returnable
              </label>
            </div>
          </div>

          <div className="gatepass-row">
            <div className="gatepass-column">
              <div className="gatepass-input-group">
                <input
                  type="text"
                  placeholder="Origin Address"
                  name="from"
                  className="gatepass-input"
                  onChange={handleFormChange}
                  value={formData.from}
                  required
                />
              </div>
              <div className="gatepass-input-group">
                <input
                  type="text"
                  placeholder="Delivered By"
                  name="deliveredBy"
                  className="gatepass-input"
                  onChange={handleFormChange}
                  value={formData.deliveredBy}
                  required
                />
              </div>
              <div className="gatepass-input-group">
                <input
                  type="text"
                  placeholder="Deliverer's Phone"
                  name="phoneNoFrom"
                  className="gatepass-input"
                  onChange={handleFormChange}
                  value={formData.phoneNoFrom}
                  required
                />
              </div>
            </div>
            <div className="gatepass-column">
              <div className="gatepass-input-group">
                <input
                  type="text"
                  placeholder="Destination Address"
                  name="to"
                  className="gatepass-input"
                  onChange={handleFormChange}
                  value={formData.to}
                  required
                />
              </div>
              <div className="gatepass-input-group">
                <input
                  type="text"
                  placeholder="Receiver"
                  name="receiver"
                  className="gatepass-input"
                  onChange={handleFormChange}
                  value={formData.receiver}
                  required
                />
              </div>
              <div className="gatepass-input-group">
                <input
                  type="text"
                  placeholder="Receiver's Phone"
                  name="phoneNoTo"
                  className="gatepass-input"
                  onChange={handleFormChange}
                  value={formData.phoneNoTo}
                  required
                />
              </div>
            </div>
          </div>
          <table className="gatepass-table">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Model No.</th>
                <th>Serial No.</th>
                <th>Asset Tag</th>
                <th>Quantity</th>
                <th>Reason</th>
                <th>Remarks</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      name="name"
                      value={item.name}
                      onChange={(event) => handleChange(index, event)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="model"
                      value={item.model}
                      onChange={(event) => handleChange(index, event)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="serialNumber"
                      value={item.serialNumber}
                      onChange={(event) => handleChange(index, event)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="assetTag"
                      value={item.assetTag}
                      onChange={(event) => handleChange(index, event)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="quantity"
                      value={item.quantity}
                      onChange={(event) => handleChange(index, event)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="reason"
                      value={item.reason}
                      onChange={(event) => handleChange(index, event)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="remarks"
                      value={item.remarks}
                      onChange={(event) => handleChange(index, event)}
                    />
                  </td>
                  <td>
                    <button className='delete-item-btn' type="button" onClick={() => removeItem(index)}>Remove</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button className='add-item-btn' type="button" onClick={addItem}>Add Item</button>
          <button type="submit">Preview</button>
        </form>
      )}

      {/* Back Button */}
      <div className="back-button-container">
        <button 
          className="back-button" 
          onClick={() => navigate(-1)}
        >
          <i className="fas fa-arrow-left"></i> Back
        </button>
      </div>

    </div>
  );
};

export default GatePassForm;
