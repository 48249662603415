// src/components/Dashboard/Header.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import apiBaseUrl from '../../config';  // Importing the base URL from config

const Header = () => {
  const navigate = useNavigate();

  const getCSRFToken = () => {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
    return cookieValue || '';
  };

  const handleLogout = async () => {
    try {
      const csrfToken = getCSRFToken();
      const response = await fetch(`${apiBaseUrl}/api/users/logout/`, {  // Corrected the backticks
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        credentials: 'include',  // Important for session-based authentication
      });

      if (response.ok) {
        // Navigate to the login page (root URL in this case)
        navigate('/');
      } else {
        console.error('Logout failed:', response.statusText);
        alert('Logout failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during logout:', error);
      alert('An error occurred during logout. Please try again.');
    }
  };

  return (
    <div className="header">
      <h1 style={{ color: '#de1b1b' }}>Welcome to the Dashboard</h1>
      <button style={{ width: '1000px' }} onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Header;
