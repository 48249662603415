// src/components/Sidebar/Sidebar.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getCsrfToken } from '../../utils/csrf';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import apiBaseUrl from '../../config';  // Import the base URL from config

const Sidebar = () => {
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    fetchUserData(); // Fetch user data when the component mounts
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/users/current_user/`, {  // Use apiBaseUrl
        headers: {
          'X-CSRFToken': getCsrfToken(),
        },
        withCredentials: true, // Include cookies in the request
      });
      
      // Assuming the response contains user data with a role field
      setUserRole(response.data.role);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  return (
    <div className="sidebar">
      <h2 style={{ color: '#D3D3D3', fontSize: '18px' }}>Gatepass System</h2>
      <ul>
        <li><Link to="/gatepassform">Create Gatepass</Link></li>
        <li><Link to="/gatepasses">Gatepasses</Link></li>
        {userRole === 'Manager' && (
          <li><Link to="/signup">Sign Up</Link></li>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
