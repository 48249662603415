// src/utils/api.js
import axios from 'axios';
import { getCsrfToken } from './csrf';
import apiBaseUrl from '../config';  // Import the base URL from config


// Function to fetch gate passes
export const fetchGatePasses = async () => {
  const csrfToken = getCsrfToken(); // Retrieve the CSRF token
  const response = await fetch(`${apiBaseUrl}/api/gatepass/list/`, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken, // Include CSRF token in the headers
    },
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

// Function to fetch gate pass by ID
export const fetchGatePassById = async (id) => {
  const csrfToken = getCsrfToken();

  try {
    const response = await axios.get(
      `${apiBaseUrl}/api/gatepass/${id}/`,
      {
        headers: {
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true, // Ensure credentials (cookies) are sent
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch gate pass: ${error.response?.data?.detail || error.message}`);
  }
};