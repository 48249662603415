// src/components/Dashboard/MainContent.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Dashboard.css';
import apiBaseUrl from '../../config';  // Importing the base URL from config

const MainContent = () => {
  const [statistics, setStatistics] = useState({});
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    // Fetch statistics
    axios.get(`${apiBaseUrl}/api/gatepass/dashboard/statistics/`)  // Using apiBaseUrl
      .then(response => {
        setStatistics(response.data);
      })
      .catch(error => {
        console.error('Error fetching statistics:', error);
      });

    // Fetch recent activities
    axios.get(`${apiBaseUrl}/api/gatepass/dashboard/activities/`)  // Using apiBaseUrl
      .then(response => {
        setActivities(response.data);
      })
      .catch(error => {
        console.error('Error fetching activities:', error);
      });
  }, []);

  return (
    <div className="main-content">
      <section className="stats">
        <h2>Statistics</h2>
        <div className="stat-box">Total Gatepasses: {statistics.total_gatepasses}</div>
        <div className="stat-box">Pending Requests: {statistics.pending_requests}</div>
        <div className="stat-box">Approved: {statistics.approved}</div>
        <div className="stat-box">Rejected: {statistics.rejected}</div>
      </section>
      <section className="activities">
        <h2>Recent Activities</h2>
        <ul>
          {activities.map((activity, index) => (
            <li key={index}>Gatepass #{activity.id} by {activity.username} - Status: {activity.status}</li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default MainContent;
